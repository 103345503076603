<template>
  <navigation-bar></navigation-bar>

  <div class="filter-panel">
    <label>Időszak:</label>
    <input type="date" v-model="selectedFromDate">
    <input type="date" v-model="selectedToDate">
    
    <button @click="setWeekRange('prev')">Előző hét</button>
    <button @click="setWeekRange('current')">Akt. hét</button>
    <button @click="setWeekRange('next')">Következő hét</button>

    <label>Diszpécser:</label>
    <select v-model="selectedDisponent">
      <option value="all">Összes</option>
      <option v-for="disp in disponents" :key="disp.name" :value="disp.name">
        {{ disp.name }}
      </option>
    </select>

    <button @click="fetchTasks">Adatok lehívása</button>
  </div>

  <div class="gantt-container">
    <!-- Linke Tabelle -->
    <div class="gantt-left">
      <table class="tasks-table">
        <thead>
          <tr>
            <th>Diszp.</th>
            <th>Rendsz</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group in groupedTasks" :key="group.rendszam">
            <td>{{ group.akt_diszpecser }}</td>
            <td>{{ group.rendszam }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Rechte Timeline -->
    <div class="gantt-right" ref="ganttContainer">
      <div class="gantt-header">
        <div class="gantt-header-scale" :style="{ width: ganttWidth + 'px' }">
          <div
            v-for="(day, index) in daysArray"
            :key="index"
            class="gantt-header-day"
          >
            {{ day.format('DD.MM') }}
          </div>
        </div>
      </div>

      <!-- Gantt-Zeilen: Jeder LKW nur 1x, mit allen Balken -->
      <div class="gantt-body" :style="{ width: ganttWidth + 'px' }">
        <!-- 📌 Vertikale Tageslinien für bessere Orientierung -->
        <div
          v-for="(day, index) in daysArray"
          :key="'line-' + index"
          class="gantt-day-line"
          :style="{ left: `${index * (ganttWidth / daysArray.length)}px` }"
        ></div>

        <div v-for="group in groupedTasks" :key="group.rendszam" class="gantt-row">
          <div
            v-for="task in group.tasks"
            :key="task.az"
            class="gantt-bar"
            :style="barStyle(task)"
            :title="`
              Megrendelő: ${task.megrendelo}
              Útvonal: ${task.route}
              Rakodási hely: ${task.fhely}
              Lerakási hely: ${task.lhely}
              Távolság: ${task.m_km} km
              Indulás: ${task.planned_start.format('YYYY. MM. DD. HH:mm')}
              Érkezés: ${task.planned_end.format('YYYY. MM. DD. HH:mm')}
            `"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import dayjs from 'dayjs';
import axiosInstance from "@/axiosInstance";

export default {
  name: 'GanttChart',
  data() {
    return {
    selectedFromDate: dayjs().startOf('week').format('YYYY-MM-DD'),
    selectedToDate: dayjs().endOf('week').format('YYYY-MM-DD'),
    selectedDisponent: 'all',
    disponents: [],
    tasks: [],
    chartStart: dayjs().startOf('week'),  // Setzt Montag als Start
    chartEnd: dayjs().endOf('week'),       // Setzt Sonntag als Ende
    ganttWidth: window.innerWidth - 320, // Dynamische Breite

  };
},

computed: {

  
  daysArray() {
    if (!this.chartStart || !this.chartEnd) {
      console.warn("⚠️ `chartStart` oder `chartEnd` ist nicht gesetzt.");
      return []; // Falls Daten fehlen, verhindern wir Fehler
    }

    let days = [];
    let current = this.chartStart.clone().startOf('day'); // `clone()`, um Original nicht zu verändern
    while (current.isBefore(this.chartEnd) || current.isSame(this.chartEnd, 'day')) {
      days.push(current.clone()); // Wichtig: `clone()`, um Vue-Reaktivität zu erhalten
      current = current.add(1, 'day'); // Weiter zum nächsten Tag
    }
    
    console.log("📅 Generierte Tage für Timeline:", days.map(d => d.format('DD.MM.YYYY')));
    return days;
  },

  groupedTasks() {
    const grouped = {};

    // Alle Einsätze nach LKW gruppieren
    this.tasks.forEach(task => {
      if (!grouped[task.rendszam]) {
        grouped[task.rendszam] = {
          rendszam: task.rendszam,
          akt_diszpecser: task.akt_diszpecser,
          tasks: [] // Liste der Balken für diesen LKW
        };
      }
      grouped[task.rendszam].tasks.push(task);
    });

    return Object.values(grouped); // Rückgabe als Array für `v-for`
  }



},



mounted() {
  window.addEventListener("resize", this.updateGanttWidth);
  this.updateGanttWidth();
},
beforeUnmount() {
    window.removeEventListener("resize", this.updateGanttWidth);
  },

  methods: {

 updateGanttWidth() {
      if (this.$refs.ganttContainer) {
        this.ganttWidth = this.$refs.ganttContainer.clientWidth;
      }
    },


    async fetchTasks() {
  try {
    console.log("📅 Vorher: chartStart =", this.chartStart ? this.chartStart.format('YYYY-MM-DD HH:mm:ss') : "❌ nicht gesetzt");
    console.log("📅 Vorher: chartEnd =", this.chartEnd ? this.chartEnd.format('YYYY-MM-DD HH:mm:ss') : "❌ nicht gesetzt");

    const payload = {
      ceg_az: 1,
      start: this.selectedFromDate + ' 00:00:01',
      end: this.selectedToDate + ' 23:00:01',
      diszpecser: this.selectedDisponent !== 'all' ? this.selectedDisponent : '%'
    };

    const response = await axiosInstance.post('/napi_mellek/list_weekTime.php', payload, {
      headers: { 'Content-Type': 'application/json' }
    });

    if (response.data && response.data.data) {
      this.tasks = response.data.data.map(task => ({
        ...task,
        planned_start: dayjs(task.planned_start),
        planned_end: dayjs(task.planned_end)
      }));

      console.log("📌 Geladene Tasks:", this.tasks);

      // ✅ `chartStart` direkt auf `selectedFromDate` setzen
      this.chartStart = dayjs(this.selectedFromDate).startOf('day');
      this.chartEnd = dayjs(this.selectedToDate).endOf('day');

      console.log("📅 Nachher: chartStart =", this.chartStart.format('YYYY-MM-DD HH:mm:ss'));
      console.log("📅 Nachher: chartEnd =", this.chartEnd.format('YYYY-MM-DD HH:mm:ss'));


      console.log("📌 Geladene Tasks:");
this.tasks.forEach(task => {
  console.log(`LKW: ${task.rendszam}, Start: ${task.planned_start.format('YYYY-MM-DD HH:mm:ss')}, End: ${task.planned_end.format('YYYY-MM-DD HH:mm:ss')}`);
});


    } else {
      this.tasks = [];
    }
  } catch (error) {
    console.error('Fehler beim Laden der Daten:', error);
    this.tasks = [];
  }
},




    setWeekRange(type) {
      if (type === 'prev') {
        this.selectedFromDate = dayjs().subtract(1, 'week').startOf('week').format('YYYY-MM-DD');
        this.selectedToDate = dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DD');
      } else if (type === 'current') {
        this.selectedFromDate = dayjs().startOf('week').format('YYYY-MM-DD');
        this.selectedToDate = dayjs().endOf('week').format('YYYY-MM-DD');
      } else if (type === 'next') {
        this.selectedFromDate = dayjs().add(1, 'week').startOf('week').format('YYYY-MM-DD');
        this.selectedToDate = dayjs().add(1, 'week').endOf('week').format('YYYY-MM-DD');
      }
      this.fetchTasks();
    },

    barStyle(task) {
  if (!task || !task.planned_start || !task.planned_end) return {};

  const start = dayjs(task.planned_start);
  const end = dayjs(task.planned_end);

  const totalDays = this.chartEnd.diff(this.chartStart, 'day') + 1;
  const timelineWidth = document.querySelector(".gantt-body")?.offsetWidth || 700;
  const pxPerDay = timelineWidth / totalDays;
  
  // 📌 NEU: Stundenanteil des Start-Zeitpunkts innerhalb des Tages
  const startHourFraction = start.hour() / 24; // z. B. 07:00 → 7/24

  // 📌 NEU: Offset in Pixel
  const offsetDays = start.diff(this.chartStart, 'day') + startHourFraction;
  const taskDuration = end.diff(start, 'hour') / 24; // Stunden in Tagesanteile umrechnen

  return {
    left: `${offsetDays * pxPerDay}px`,
    width: `${Math.max(15, taskDuration * pxPerDay)}px`,
    backgroundColor: '#007bff'
  };
}







  }
};
</script>

<style scoped>
.gantt-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  width: 100vw;
  overflow-x: auto;
}

/* 📌 Fix 1: Einheitliche Höhe für die Kopfzeilen */
.tasks-table thead tr, .gantt-header {
  height: 40px; /* Gleiche Höhe für Tabellenkopf und Timeline-Kopf */
  line-height: 15px; /* Vertikale Zentrierung */
  padding: 0; /* Entferne unnötiges Padding */
  border-bottom: 1px solid #ccc;
  background: #f8f9fa;
  font-weight: bold;
  text-align: center;
  font-size: 0.8rem;
}

/* 📌 Fix 2: Gleiche Höhe für alle Zeilen in der Tabelle und Gantt-Ansicht */
.tasks-table tbody tr, .gantt-row {
  height: 40px; /* Einheitliche Höhe */
  font-size: 0.8rem;
}

/* 📌 Fix 3: Tabelle links */
.gantt-left {
  overflow: auto; /* Falls zu viele Zeilen */
  border-right: 1px solid #ccc;
}

.tasks-table {
  width: 100%;
  border-collapse: collapse;
}

.tasks-table th, .tasks-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

/* 📌 Fix 4: Gantt-Timeline Kopfzeile */
.gantt-header {
  display: flex;
  align-items: center;
  height: 40px; /* Gleiche Höhe wie Tabellenkopf */
  border-bottom: 1px solid #ccc;
  background: #f8f9fa;
}

.gantt-header-scale {
  display: flex;
  width: 100%;
  align-items: center;
}

.gantt-header-day {
  flex: 1;
  text-align: center;
  border-right: 1px solid #ccc;
  font-size: 0.8rem;
  line-height: 40px; /* Gleiche Höhe wie Tabellenüberschrift */
  padding: 0; /* Verhindert zu hohe Elemente */
  min-width: 50px; /* Mindestbreite für Spalten */
}

.gantt-right {
  overflow-x: auto;
  flex-grow: 1;
}

.gantt-body {
  position: relative;
  width: 100%; /* Muss mit `timelineWidth` übereinstimmen */
  overflow-x: auto; /* Falls nötig, damit Balken nicht abgeschnitten werden */
}

.gantt-day-line {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px; /* Dünne Linie */
  background-color: rgba(0, 0, 0, 0.1); /* Dezente Farbe */
}

.gantt-row {
  position: relative;
  border-bottom: 1px solid #ccc;
  width: 100%; /* Muss auf volle Breite gesetzt werden */
}

.gantt-bar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Perfekte Zentrierung */
  height: 18px; /* ❗ Kleiner & filigraner statt 30px */
  border-radius: 6px; /* ❗ Runde Ecken für ein weiches Design */
  background-color: #007bff; /* Standardblau */
  min-width: 10px; /* ❗ Kleinere Mindestbreite für kurze Balken */
  opacity: 0.85; /* ❗ Leicht transparent für eine modernere Optik */
  transition: all 0.3s ease-in-out; /* ❗ Sanfte Animation bei Änderungen */
}

/* ❗ Farbänderung beim Hover für besseren Fokus */
.gantt-bar:hover {
  opacity: 1;
  background-color: #0056b3;
  cursor: pointer;
}

</style>
