<template>
  <div>
    <navigation-bar></navigation-bar>
    <div class="container mt-4">
      <h2>Több fel-/lerakó kezelése</h2>
      <p>Megrendelés belső azonosító: {{ orderId }}</p>

      <!-- Extra felrakó (típus 1) -->
      <div class="mt-4">
        <h3>További felrakó</h3>
        <table class="table">
          <thead>
            <tr>
              <th class="sorrend-col">Sorrend</th>
              <th>Hely megnevezés</th>
              <th>Cég</th>
              <th>Cím</th>
              <th>GPS</th>
              <th>Időkapu</th>
              <th>Magasság</th>
              <th>Műveletek</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="item in extraFelrako" :key="item.az">
                <td>
                  <input 
                    type="number" 
                    class="form-control" 
                    v-model.number="item.sorrend" 
                    @blur="updateSorrend(item)"
                  />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.tav_megn" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.ceg" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.cim" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.gps" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.idokapu" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.magassag" readonly />
                </td>
                <td>
                  <button class="btn btn-danger btn-sm" @click="deleteExtraField('Felrakó', item.az)">
                    Törlés
                  </button>
                </td>
              </tr>
            </tbody>
        </table>



        

          <!-- Button zum Öffnen des Dropdown-basierten Eingabebereichs -->
          <button class="btn btn-primary btn-sm" @click="openMultipleFelrakoInput">
          További felrakó hozzáadása
        </button>

        <!-- Block für zusätzliche Felrakó-Felder – hier vorübergehend mit v-if und einem roten Rahmen -->
        <div v-if="showMultipleFelrako" class="form-group row align-items-center" style="border: 2px solid red; padding:10px;">
            <!-- Sorrend-Feld ohne Label in der ersten Spalte -->
            <div class="col-sm-1 col-md-1">
              <input type="number"
                    class="form-control"
                    v-model="extraFelrakoInput.sorrend"
                    placeholder="Sorrend">
            </div>
            <!-- Felrakó Label und Auswahl -->
            <label class="col-sm-1 col-md-1 col-form-label">Felrakó</label>
            <div class="col-sm-2 col-md-2">
              <select id="felrako_extra_select"
                      class="form-control"
                      v-model="extraFelrakoInput.selectedFelrako">
              </select>
            </div>
            <!-- Felhely Label und Auswahl für extra Felrakó -->
          <label class="col-sm-1 col-md-1 col-form-label">Felhely</label>
          <div class="col-sm-2 col-md-2">
            <select id="felhely_extra_select"
                    class="form-control"
                    v-model="extraFelrakoInput.selectedFelhely">
            </select>
          </div>
          <!-- Buttons für extra Felrako -->
          <div class="col-sm-3 col-md-3">
            <button type="button" class="btn btn-success" @click="saveExtraFelrakoInput">Mentés</button>
            <button type="button" class="btn btn-secondary" @click="cancelExtraFelrakoInput">Megszakit</button>
          </div>
        
        </div>


      </div>


      <!-- Extra felhely (típus 2) -->
      <div class="mt-5">
        <h3>További lerakó</h3>
        <table class="table">
          <thead>
            <tr>
              <th class="sorrend-col">Sorrend</th>
              <th>Hely megnevezés</th>
              <th>Cég</th>
              <th>Cím</th>
              <th>GPS</th>
              <th>Időkapu</th>
              <th>Magasság</th>
              <th>Műveletek</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="item in extraFelhely" :key="item.az">
                <td>
                  <input 
                    type="number" 
                    class="form-control" 
                    v-model.number="item.sorrend" 
                    @blur="updateSorrend(item)"
                  />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.tav_megn" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.ceg" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.cim" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.gps" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.idokapu" readonly />
                </td>
                <td>
                  <input type="text" class="form-control" v-model="item.magassag" readonly />
                </td>
                <td>
                  <button class="btn btn-danger btn-sm" @click="deleteExtraField('Lerakó', item.az)">
                    Törlés
                  </button>
                </td>
              </tr>
            </tbody>
        </table>
        <button class="btn btn-primary btn-sm" @click="openMultipleLerakoInput">
            További lerakó hozzáadása
          </button>


          <!-- Eingabebereich für Lerakó (Dropdown) -->
          <div v-if="showMultipleLerako" class="form-group row align-items-center" style="border: 2px solid red; padding:10px;">
            <!-- Sorrend -->
            <div class="col-sm-1 col-md-1">
              <input type="number" class="form-control" v-model="extraLerakoInput.sorrend" placeholder="Sorrend">
            </div>
            <!-- Lerakó Dropdown -->
            <label class="col-sm-1 col-md-1 col-form-label">Lerakó</label>
            <div class="col-sm-2 col-md-2">
              <select id="lerako_extra_select" class="form-control" v-model="extraLerakoInput.selectedLerako"></select>
            </div>
            <!-- Lerakó hely Dropdown -->
            <label class="col-sm-1 col-md-1 col-form-label">Lerakó hely</label>
            <div class="col-sm-2 col-md-2">
              <select id="lerakohely_extra_select" class="form-control" v-model="extraLerakoInput.selectedLerakoHely"></select>
            </div>
            <!-- Buttons -->
            <div class="col-sm-3 col-md-3">
              <button type="button" class="btn btn-success btn-sm" @click="saveExtraLerakoInput">Mentés</button>
              <button type="button" class="btn btn-secondary btn-sm" @click="cancelExtraLerakoInput">Megszakít</button>
            </div>
          </div>
        
      </div>

      <!-- Mentés- és Megszakít gombok -->
      <div class="mt-4">
   
        <button class="btn btn-secondary ml-2" @click="cancel">
          Vissza
        </button>
      </div>



    </div>
  </div>
</template>

<script>
import axiosInstance from '@/axiosInstance';
import { mapGetters } from 'vuex';
import $ from 'jquery';

export default {
  data() {
    return {
      extraFelrako: [], // Extra felrakó (típus "1")
      extraFelhely: [],  // Extra felhely (típus "2")
      // Neue Properties für Dropdown-basierte Eingabe
      showMultipleFelrako: false,
      extraFelrakoInput: {
        sorrend: '',
        selectedFelrako: null,
        selectedFelrakoName: '',
        selectedFelhely: null,
        selectedFelhelyName: ''
      },


      showMultipleLerako: false,
      extraLerakoInput: {
        sorrend: '',
        selectedLerako: null,
        selectedLerakoName: '',
        selectedLerakoHely: null,
        selectedLerakoHelyName: ''
      }


     
     
    };
  },
  computed: {
    ...mapGetters(["getorderData"]),
    ...mapGetters(['getUserData']),
        fsz() {
            return this.getUserData ? this.getUserData.fsz : null;
        },
    orderId() {
      // Feltételezzük, hogy a 'az' érték kerül használatra, mint ftr_megrAz.
      return this.getorderData?.napifo_az || null;
    },

    cegAz() {
      // Feltételezzük, hogy a cég azonosítója a getorderData-ban m_cegaz alatt szerepel.
      return this.getorderData?.cegaz || null;
    },
    
  },
  mounted() {
    this.fetchExtraFields();
  },
  methods: {

    async updateSorrend(item) {
    // Erstelle das Payload mit der ID und dem neuen Sorrend-Wert
    const payload = {
      ceg_az: this.cegAz,
      az: item.az,
      sorrend: item.sorrend
    };
    try {

      console.log("sorrend modositas",payload);
      const response = await axiosInstance.post('/fellerakok/update_sorrend.php', payload);
      if (response.data.success) {
        console.log("Sorrend wurde aktualisiert.");
      } else {
        alert("Hiba a sorrend módosítása során.");
      }
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Sorrend-Werts:", error);
      alert("Hiba történt a sorrend módosítása során.");
    }
  },



/***********    + lerako  *********************************************************** */
openMultipleLerakoInput() {
  this.showMultipleLerako = true;
  this.$nextTick(() => {
    this.initSelect2LerakoExtra();
    this.initSelect2LerakoHelyExtra();
  });
},
//******************************************************************************************************** */
async saveExtraLerakoInput() {
    const lerakoName = $('#lerako_extra_select').select2('data')[0]?.text || '';
  const lerakoHelyName = $('#lerakohely_extra_select').select2('data')[0]?.text || '';
  
  const payloadLerako = {
    ftr_megrAz: this.orderId,
    tipus: "2",  // Typ "2" für Lerakó
    sorrend: this.extraLerakoInput.sorrend || 0,
    tav_az: this.extraLerakoInput.selectedLerako,
    mhely_az: this.extraLerakoInput.selectedLerakoHely,
    felh: this.fsz,
    ceg_az: this.cegAz
  };

  try {
    console.log("Sende extra Lerakó:", payloadLerako);
    const res = await axiosInstance.post('/fellerakok/create_fellerako.php', payloadLerako);
    console.log("Erfolg extra Lerakó:", res.data);
    
    // Optional: Neuen Eintrag lokal hinzufügen (falls gewünscht)
    
    const newEntry = {
      az: Date.now(),
      sorrend: payloadLerako.sorrend,
      selectedLerako: payloadLerako.tav_az,
      selectedLerakoName: lerakoName,
      selectedLerakoHely: payloadLerako.mhely_az,
      selectedLerakoHelyName: lerakoHelyName
    };
    this.extraFelhely.push(newEntry);
    
    // Reset des Eingabebereichs
    this.extraLerakoInput = {
      sorrend: '',
      selectedLerako: null,
      selectedLerakoName: '',
      selectedLerakoHely: null,
      selectedLerakoHelyName: ''
    };
    this.showMultipleLerako = false;
    
    // Seite neu laden (oder alternativ die Daten neu laden)
    await this.fetchExtraFields();
    
  } catch (error) {
    console.error("Fehler beim Speichern des extra Lerakó:", error);
    alert("Fehler beim Speichern des extra Lerakó, bitte den Programmierer benachrichtigen.");
  }
},
/****************************************************************************************************/
cancelExtraLerakoInput() {
  this.extraLerakoInput = {
    sorrend: '',
    selectedLerako: null,
    selectedLerakoName: '',
    selectedLerakoHely: null,
    selectedLerakoHelyName: ''
  };
  this.showMultipleLerako = false;
},

// Initialisiert Select2 für das extra Lerakó-Feld
initSelect2LerakoExtra() {
  const vm = this;
  $('#lerako_extra_select').select2({
    placeholder: 'Lerakó kiválasztása...',
    allowClear: true,
    minimumInputLength: 2,
    ajax: {
      url: 'https://bogir.hu/V2/api/tav/list_tav.php',
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function (params) {
        const payload = {
          search: params.term,
          ceg_az: vm.cegAz,
        };
        console.log('Daten für extra Lerakó:', payload);
        return JSON.stringify(payload);
      },
      processResults: function (response) {
        console.log('Antwort vom Server (extra Lerakó):', response);
        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.tav_az,
              text: item.tav_megn,
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort (extra Lerakó):', response);
          return { results: [] };
        }
      },
      cache: true,
      error: function (jqXHR, textStatus, errorThrown) {
        console.error('Fehler bei extra Lerakó:', textStatus, errorThrown);
      }
    }
  });
  $('#lerako_extra_select').on('select2:select', function(e) {
    const selectedData = e.params.data;
    vm.extraLerakoInput.selectedLerako = selectedData.id;
    console.log('Extra Lerakó ausgewählt:', selectedData);
  });
  $('#lerako_extra_select').on('select2:clear', function() {
    vm.extraLerakoInput.selectedLerako = null;
    console.log('Extra Lerakó Auswahl zurückgesetzt.');
  });
},

// Initialisiert Select2 für das extra Lerakó hely-Feld
initSelect2LerakoHelyExtra() {
  const vm = this;
  $('#lerakohely_extra_select').select2({
    placeholder: 'Lerakó hely kiválasztása...',
    allowClear: true,
    ajax: {
      url: 'https://bogir.hu/V2/api/hely/list_hely.php',
      type: 'POST',
      contentType: 'application/json',
      dataType: 'json',
      delay: 250,
      headers: {
        'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
      },
      data: function () {
        const payload = {
          hely_tavaz: vm.extraLerakoInput.selectedLerako || 0,
        };
        console.log('Daten für extra Lerakó hely:', payload);
        return JSON.stringify(payload);
      },
      processResults: function (response) {
        console.log('Antwort vom Server (extra Lerakó hely):', response);
        if (response && response.data && Array.isArray(response.data)) {
          return {
            results: response.data.map(item => ({
              id: item.hely_az,
              text: `${item.hely_ceg} - ${item.hely_cim}`,
            })),
          };
        } else {
          console.error('Fehlerhafte API-Antwort (extra Lerakó hely):', response);
          return { results: [] };
        }
      },
      cache: true
    }
  });
  $('#lerakohely_extra_select').on('select2:select', function(e) {
    const selectedData = e.params.data;
    vm.extraLerakoInput.selectedLerakoHely = selectedData.id;
    console.log('Extra Lerakó hely ausgewählt:', selectedData);
  });
  $('#lerakohely_extra_select').on('select2:clear', function() {
    vm.extraLerakoInput.selectedLerakoHely = null;
    console.log('Extra Lerakó hely Auswahl zurückgesetzt.');
  });
},



/********    + lerako vege*************************************************** */

    async fetchExtraFields() {



      if (!this.orderId || !this.cegAz) {
        console.warn("A megrendelés vagy cég azonosítója nem érhető el – az extra mezők nem tölthetők be.");
        return;
      }
      const postData = {
        ceg_az: this.cegAz,
        ftr_megrAz: this.orderId
      };
      try {
        console.log("Extra mezők betöltése:", postData);
        const response = await axiosInstance.post("/fellerakok/list_megrAZ.php", postData);
        if (response.status === 200 && response.data.data) {
          // A típus alapján szétválasztjuk: "1" = felrakó, "2" = felhely
          this.extraFelrako = response.data.data.filter(item => item.tipus === "1");
          this.extraFelhely = response.data.data.filter(item => item.tipus === "2");
        } else {
          console.log("Nem találhatók extra mezők:", response);
        }
      } catch (error) {
        console.error("Hiba az extra mezők betöltése során:", error);
      }
    },

    openMultipleFelrakoInput() {
      this.showMultipleFelrako = true;
      this.$nextTick(() => {
        this.initSelect2FelrakoExtra();
        this.initSelect2FelhelyExtra();
      });
    },

    //**   kell ez? */
    addExtraFelrako() {
      this.showMultipleFelrako = true;
    },
    addExtraFelhely() {
      this.extraFelhely.push({
        az: Date.now(),
        sorrend: 0,
        tav_megn: "",
        ceg: "",
        tipus: "2"
      });
    },

//******************************************************** */
async saveExtraFelrakoInput() {
 
  // Ermitteln der Anzeige-Bezeichnungen aus Select2 (optional)
  const felrakoName = $('#felrako_extra_select').select2('data')[0]?.text || '';
  const felhelyName = $('#felhely_extra_select').select2('data')[0]?.text || '';
  
  // Payload zusammenstellen – Verwende hier die Order-ID aus this.orderId
  const payloadFelrako = {
    ftr_megrAz: this.orderId,  // Meistens ist dies die Order-ID, unter der der Eintrag verknüpft wird
    tipus: "1",               // Typ "1" steht für Felrakó
    sorrend: this.extraFelrakoInput.sorrend || 0,
    tav_az: this.extraFelrakoInput.selectedFelrako,   // ID des ausgewählten Felrakó
    mhely_az: this.extraFelrakoInput.selectedFelhely,  // ID des ausgewählten Felhely
    felh: this.fsz,           // Benutzer-ID (aus Vuex)
    ceg_az: this.cegAz        // Firmen-ID
  };

  try {
    console.log("Sende extra Felrakó:", payloadFelrako);
    const res = await axiosInstance.post('/fellerakok/create_fellerako.php', payloadFelrako);
    console.log("Erfolg extra Felrakó:", res.data);
    
    // Optional: Neuen Eintrag lokal hinzufügen, falls du ihn sofort in der Liste sehen möchtest
    const newEntry = {
      az: Date.now(), // Temporäre ID
      sorrend: payloadFelrako.sorrend,
      selectedFelrako: payloadFelrako.tav_az,
      selectedFelrakoName: felrakoName,
      selectedFelhely: payloadFelrako.mhely_az,
      selectedFelhelyName: felhelyName
    };
    this.extraFelrako.push(newEntry);
    
    // Reset des Eingabebereichs
    this.extraFelrakoInput = {
      sorrend: '',
      selectedFelrako: null,
      selectedFelrakoName: '',
      selectedFelhely: null,
      selectedFelhelyName: ''
    };
    this.showMultipleFelrako = false;

    await this.fetchExtraFields();

  } catch (error) {
    console.error("Fehler beim Speichern des extra Felrakó:", error);
    alert("Fehler beim Speichern des extra Felrakó, bitte den Programmierer benachrichtigen.");
  }
},

/*************************************************************************** */
    cancelExtraFelrakoInput() {
      this.extraFelrakoInput = {
        sorrend: '',
        selectedFelrako: null,
        selectedFelrakoName: '',
        selectedFelhely: null,
        selectedFelhelyName: ''
      };
      this.showMultipleFelrako = false;
    },


/**************************************************************************** */
    // Initialisiert Select2 für das extra Felrakó-Feld
    initSelect2FelrakoExtra() {
      const vm = this;
      $('#felrako_extra_select').select2({
        placeholder: 'Felrakó kiválasztása...',
        allowClear: true,
        minimumInputLength: 2,
        ajax: {
          url: 'https://bogir.hu/V2/api/tav/list_tav.php',
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          delay: 250,
          headers: {
            'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
          },
          data: function (params) {
            const payload = {
              search: params.term,
              ceg_az: vm.cegAz,
            };
            console.log('Daten für extra Felrakó:', payload);
            return JSON.stringify(payload);
          },
          processResults: function (response) {
            console.log('Antwort vom Server (extra Felrakó):', response);
            if (response && response.data && Array.isArray(response.data)) {
              return {
                results: response.data.map(item => ({
                  id: item.tav_az,
                  text: item.tav_megn,
                })),
              };
            } else {
              console.error('Fehlerhafte API-Antwort (extra Felrakó):', response);
              return { results: [] };
            }
          },
          cache: true,
          error: function (jqXHR, textStatus, errorThrown) {
            console.error('Fehler bei extra Felrakó:', textStatus, errorThrown);
          },
        },
      });
      $('#felrako_extra_select').on('select2:select', function(e) {
        const selectedData = e.params.data;
        vm.extraFelrakoInput.selectedFelrako = selectedData.id;
        console.log('Extra Felrakó ausgewählt:', selectedData);
      });
      $('#felrako_extra_select').on('select2:clear', function() {
        vm.extraFelrakoInput.selectedFelrako = null;
        console.log('Extra Felrakó Auswahl zurückgesetzt.');
      });
    },
    // Initialisiert Select2 für das extra Felhely-Feld
    initSelect2FelhelyExtra() {
      const vm = this;
      $('#felhely_extra_select').select2({
        placeholder: 'Felhely kiválasztása...',
        allowClear: true,
        ajax: {
          url: 'https://bogir.hu/V2/api/hely/list_hely.php',
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          delay: 250,
          headers: {
            'Authorization': 'Basic ' + btoa('Admin_2024$$:S3cure+P@ssw0rd2024!'),
          },
          data: function () {
            const payload = {
              hely_tavaz: vm.extraFelrakoInput.selectedFelrako || 0,
            };
            console.log('Daten für extra Felhely:', payload);
            return JSON.stringify(payload);
          },
          processResults: function (response) {
            console.log('Antwort vom Server (extra Felhely):', response);
            if (response && response.data && Array.isArray(response.data)) {
              return {
                results: response.data.map(item => ({
                  id: item.hely_az,
                  text: `${item.hely_ceg} - ${item.hely_cim}`,
                })),
              };
            } else {
              console.error('Fehlerhafte API-Antwort (extra Felhely):', response);
              return { results: [] };
            }
          },
          cache: true,
        },
      });
      $('#felhely_extra_select').on('select2:select', function(e) {
        const selectedData = e.params.data;
        vm.extraFelrakoInput.selectedFelhely = selectedData.id;
        console.log('Extra Felhely ausgewählt:', selectedData);
      });
      $('#felhely_extra_select').on('select2:clear', function() {
        vm.extraFelrakoInput.selectedFelhely = null;
        console.log('Extra Felhely Auswahl zurückgesetzt.');
      });
    },

    //************************************************************************************ */
    async deleteExtraField(type, id) {
  if (confirm("Biztosan törli ezt a "+type +"-t ?")) {
    const postData = {
      ceg_az: this.cegAz,
      az: id
    };
    try {
      const response = await axiosInstance.post("/fellerakok/set_storno.php", postData);
      console.log("Törlés válasz:", response.data);
      // Wenn das Ergebnis 1 (true) ist, entferne den Eintrag lokal:
      if (response.data == 1) {
        if (type === "Felrakó") {
          this.extraFelrako = this.extraFelrako.filter(item => item.az !== id);
        } else if (type === "Lerakó") {
          this.extraFelhely = this.extraFelhely.filter(item => item.az !== id);
        }
        //alert("Fel/lerakó törölve!");
      } else {
        alert("Hiba történt a törlés során!");
      }
    } catch (error) {
      console.error("Hiba a törlés során:", error);
      alert("Hiba történt a törlés során!");
    }
  }
},
    async saveExtraFields() {
      if (!this.orderId || !this.cegAz) {
        console.warn("A megrendelés vagy cég azonosítója nem érhető el – az extra mezők nem menthetők el.");
        return;
      }
      const postData = {
        ceg_az: this.cegAz,
        ftr_megrAz: this.orderId,
        extraFelrako: this.extraFelrako,
        extraFelhely: this.extraFelhely
      };
      try {
        console.log("Extra mezők mentése:", postData);
        const response = await axiosInstance.post("/fellerakok/api_update_extra_fields.php", postData);
        if (response.data.success) {
          alert("Változtatások elmentve!");
          this.fetchExtraFields();
        } else {
          console.error("Hiba a mentés során:", response.data);
          alert("Hiba az extra mezők mentése során.");
        }
      } catch (error) {
        console.error("Hiba az extra mezők mentése során:", error);
      }
    },
    cancel() {
      this.$router.go(-1);
 
    }
  }
};
</script>

<style scoped>
.table {
  margin-bottom: 20px;
}
.new-field-highlight {
  border: 2px solid #007bff;
  border-radius: 8px;
  padding: 10px;
  background-color: #e7f3ff;
  margin-bottom: 12px;
}
.extra-field {
  margin-bottom: 10px;
}
.ml-2 {
  margin-left: 0.5rem;
}
.sorrend-col {
  width: 80px; /* Passe den Wert nach Bedarf an */
}



</style>
