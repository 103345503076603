import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate"; // Importieren

// Enum für Benutzertypen
export const UserTypes = Object.freeze({
  ADMIN: 'admin',
  FUVAR_SZERVEZŐ: 'fuvarszervező',
  DISZPÉCSER: 'diszpécser'
});


export default createStore({
  state: {
    userData: {  
      szem_az: '',
      nev: '',
      fsz: '',
      ceg_az: '',
      first_sign: '',
      tipus: '',
      csoport: ''
    },

    weekPostData: {}, // Hier werden die postData-Daten gespeichert aus SajatOsszes

    mapFilters: {
      dateFrom: "", // Startdatum
      dateTo: "", // Enddatum
      fuvarszervezo: "", // Fuvarszervező Auswahl
      diszpecser: "", // Diszpécser Auswahl
      felrako: false, // Standardmäßig "Ja" für Felrakó
      lerako: false, // Standardmäßig "Ja" für Lerakó
      teherautok: false, // Standardmäßig "Nein" für Teherautók
      vonalak: false, // Standardmäßig "Nein" für Teherautók
    },

    wochen_auswahl: {
      ceg_az: null, // Aktuelle Firma
      fsz: null,    // Benutzer-Fuvarszervező
      currentYear: null, // Aktuelles Jahr
      currentWeek: null, // Aktuelle Woche
    },

    order: {
      az: null,
      cegaz: null,
      datum: null,
      viszonylat: null,
      napifo_az: null,
      napimellek_az: null,
      tipus: null,
      megrendelo: null,
      cikk: null,
      szall_hatarido: null,
      hivatkozasi_szam: null,
      oldal:null,
      fo_megjegyzes:null,
      csoport:null,
      fsz:null, 
      gmp:null,
      m_megrendeloaz:null,
      m_felrakoaz:null,
      m_lerakoaz:null,
      m_cikkaz:null,
      belsoHivatkozas:null
      
    },
    
    napi_datum: null,

    logParams: {  // Neue State für die ChangeLog-Parameter
      user: '',
      table_name: '',
      record_id: 0
    },
    truckData: {
      az: null,
      fo_az: null,
      rendsz_az: null,
      rendszam: '',
      potkocsi: '',
      felsegjel: '',
      fuvarozo: '',
      felrakas_napja: '',
      lerakas_napja: '',
      fel_suly: null,
      le_suly: null,
      rakodasi_szam: '',
      billenes_kod: '',
      fuv_az: null,
      datum: '',
      megjegyzes: '',
      sorrend: null,
      status: null,
      storno: null,
      made: '',
      felh: '',
      timest: '',
      mod_felh: '',
      sajat: null,
      fordulo: null,
      ev: null,
      ho: null,
      het: null,
      het_napja: null,
      m_tipmenny: null,
      ceg_az: null,
      alv_artipaz:null,
      alv_egysegar:null,
      eltero_ar:null,
      alv_egysegarme:null
      
    }
   
  },
  getters: {
    getWochenAuswahl: (state) => state.wochen_auswahl,
    getTruckData: state => state.truckData,
    getUserData: state => state.userData,
    getorderData: state => state.order,
    getUserType: state => state.userData.tipus,  // Getter für den Benutzertyp
    isAdmin: state => state.userData.tipus === UserTypes.ADMIN,  // Prüfen, ob der Benutzer ein Admin ist
    isFuvarszervezo: state => state.userData.tipus === UserTypes.FUVAR_SZERVEZŐ,  // Prüfen, ob der Benutzer ein Fuvarszervező ist
    isDiszpecser: state => state.userData.tipus === UserTypes.DISZPÉCSER,  // Prüfen, ob der Benutzer ein Diszpécser ist
    getLogParams: state => state.logParams,  // Getter für die ChangeLog-Parameter
    getNapiDatum: state => state.napi_datum,  // Getter, um das Datum abzurufen
    getWeekPostData: state => state.weekPostData, // Gibt die gespeicherten postData zurück
    getMapFilters(state) {
      return state.mapFilters;
    }
    
  },
  mutations: {

        setWeekPostData(state, payload) {
          state.weekPostData = payload; // Speichert die postData aus SajatOsszes
        },

        setTruckData(state, truckData) {
          state.truckData = truckData;
        },
        setWochenAuswahl(state, payload) {
          state.wochen_auswahl = payload;
        },
        setNapiDatum(state, newDate) {
          if (newDate) {
            console.log('Altes napiDatum:', state.napi_datum);
            console.log('Neues napiDatum:', newDate);
            state.napi_datum = newDate;
          } else {
            console.warn('Undefiniertes oder null-Datum wurde übergeben. Änderung abgelehnt.');
          }
        },
          // Neue Mutation, um Truck-Daten zu leeren
        clearTruckData(state) {
          state.truckData = {
            az: null,
            fo_az: null,
            rendsz_az: null,
            rendszam: null,
            potkocsi: null,
            felsegjel: null,
            fuvarozo: null,
            felrakas_napja: null,
            lerakas_napja: null,
            fel_suly: null,
            le_suly: null,
            rakodasi_szam: null,
            billenes_kod: null,
            fuv_az: null,
            datum: null,
            megjegyzes: null,
            sorrend: null,
            status: null,
            storno: null,
            made: null,
            felh: null,
            timest: null,
            mod_felh: null,
            sajat: null,
            fordulo: null,
            ev: null,
            ho: null,
            het: null,
            het_napja: null,
            m_tipmenny: null,
            ceg_az: null,
            alv_artipaz:null,
            alv_egysegar:null,
            eltero_ar:null,
            alv_egysegarme:null


          };
        },
        
        setUserData(state, userData) {
            state.userData.szem_az = userData.szem_az;
            state.userData.nev = userData.nev;
            state.userData.fsz = userData.fsz;
            state.userData.ceg_az = userData.ceg_az;
            state.userData.first_sign = userData.firstSign;
            state.userData.tipus = userData.tipus;
            state.userData.csoport = userData.csoport;
          },
        
          setorderData(state, orderData) {
          state.order = orderData;
          console.log('orderData', orderData);
        },
        
        setLogParams(state, logParams) {  // Neue Mutation zum Setzen der ChangeLog-Parameter
          state.logParams = logParams;
        },
        SET_MAP_FILTER(state, { key, value }) {
          state.mapFilters[key] = value;
          console.log('SET_MAP_FILTER',this.$store.state.mapFilters);
        },
        
        SET_ALL_MAP_FILTERS(state, filters) {
          state.mapFilters = { ...state.mapFilters, ...filters };
          console.log('SET_ALL_MAP_FILTERS',this.$store.state.mapFilters);
        }


      },
  
  
  actions: {

    saveWeekPostData({ commit }, postData) {
      commit('setWeekPostData', postData); // Speichert die Daten in der Mutation aus SajatOsszes
    },

    saveWochenAuswahl({ commit }, auswahl) {
      commit("setWochenAuswahl", auswahl);

      console.log('-->store_woche',this.state.wochen_auswahl);

    },

    updateMapFilter({ commit }, payload) {
      commit("SET_MAP_FILTER", payload);
    },
    
    updateAllMapFilters({ commit }, filters) {
      commit("SET_ALL_MAP_FILTERS", filters);
    }
    
   
    
  },

  plugins: [
    createPersistedState({
      paths: ["mapFilters", "userData", "weekPostData"], // Diese Daten werden im localStorage gespeichert
    }),
  ],

  modules: {
    // Falls du dein Store in Module unterteilen möchtest
  }


})
